import '@/styles/globals.css';
import type { AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import fetchJson from '@/lib/fetch/fetch-json';
import { ThemeProvider } from '@/components/theme/theme-provider';
import { Toaster } from '@/components/ui/toaster';
import AuthWrapper from '@/components/wrappers/authWrapper';
import Layout from '@/components/layouts/defaultLayout';
import { trpc } from '@/lib/trpc/trpc';
function App({
  Component,
  pageProps
}: AppProps) {
  return <SWRConfig value={{
    fetcher: fetchJson,
    onError: error => {
      console.error(error);
    }
  }} data-sentry-element="SWRConfig" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <ThemeProvider attribute='class' defaultTheme='system' enableSystem disableTransitionOnChange data-sentry-element="ThemeProvider" data-sentry-source-file="_app.tsx">
        <div>
          <AuthWrapper data-sentry-element="AuthWrapper" data-sentry-source-file="_app.tsx">
            <Layout data-sentry-element="Layout" data-sentry-source-file="_app.tsx">
              <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
              <Toaster data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
            </Layout>
          </AuthWrapper>
        </div>
      </ThemeProvider>
    </SWRConfig>;
}
export default trpc.withTRPC(App);