'use client';

import { ModeToggle } from '../theme/mode-toggle';
import packageInfo from '../../../package.json';
import { trpc } from '@/lib/trpc/trpc';
export default function Footer() {
  const APIversion = trpc.APIInfo.apiVersion.useQuery();
  return <footer className='h-16 items-center py-2' data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <div className='flex'>
        <div className='grow flex-col pb-2'>
          <p className='p-3 text-xs font-extralight'>
            © 2024 Echo Ready Systems
          </p>
        </div>
        <div className='hidden flex-col pb-2 sm:block'>
          <p className='p-3 text-xs font-extralight'>
            Client Build - {packageInfo.version}
          </p>
        </div>
        <div className='hidden flex-col pb-2 sm:block'>
          <p className='p-3 text-xs font-extralight'>
            API Build - {APIversion.data?.data}
          </p>
        </div>
        <div className='pr-3 text-right'>
          <ModeToggle data-sentry-element="ModeToggle" data-sentry-source-file="footer.tsx" />
        </div>
      </div>
    </footer>;
}