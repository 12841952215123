import { LogtoContext } from '@logto/next';
import { createContext } from 'react';

export const UserContext = createContext<LogtoContext>({
  isAuthenticated: false,
  claims: undefined,
  accessToken: undefined,
  userInfo: undefined,
  scopes: undefined,
  organizationTokens: undefined,
});
