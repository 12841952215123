'use client';

import * as React from 'react';
import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { useTheme } from 'next-themes';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
export function ModeToggle() {
  const {
    setTheme
  } = useTheme();
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="ModeToggle" data-sentry-source-file="mode-toggle.tsx">
      <DropdownMenuTrigger asChild data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="mode-toggle.tsx">
        <Button variant='outline' size='icon' data-sentry-element="Button" data-sentry-source-file="mode-toggle.tsx">
          <SunIcon className='h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0' data-sentry-element="SunIcon" data-sentry-source-file="mode-toggle.tsx" />
          <MoonIcon className='absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100' data-sentry-element="MoonIcon" data-sentry-source-file="mode-toggle.tsx" />
          <span className='sr-only'>Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='end' data-sentry-element="DropdownMenuContent" data-sentry-source-file="mode-toggle.tsx">
        <DropdownMenuItem onClick={() => setTheme('light')} data-sentry-element="DropdownMenuItem" data-sentry-source-file="mode-toggle.tsx">
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('dark')} data-sentry-element="DropdownMenuItem" data-sentry-source-file="mode-toggle.tsx">
          Dark
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme('system')} data-sentry-element="DropdownMenuItem" data-sentry-source-file="mode-toggle.tsx">
          System
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
}