import Link from 'next/link';
import Footer from '../navigation/footer';
import { Sheet, SheetContent, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import Image from 'next/image';
import { Button } from '../ui/button';
export default function Login() {
  return <div className='flex h-screen flex-col' data-sentry-component="Login" data-sentry-source-file="login.tsx">
      <header className='flex items-center justify-between px-4 py-3'>
        <div className='flex items-center gap-4'>
          <div className='md:hidden'>
            <div className='flex items-center gap-4'>
              <Sheet data-sentry-element="Sheet" data-sentry-source-file="login.tsx">
                <SheetContent side={'left'} className='w-full bg-slate-600' data-sentry-element="SheetContent" data-sentry-source-file="login.tsx">
                  <SheetHeader data-sentry-element="SheetHeader" data-sentry-source-file="login.tsx">
                    <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="login.tsx">Air Reserve Menu</SheetTitle>
                  </SheetHeader>
                </SheetContent>
              </Sheet>
            </div>
          </div>
        </div>
        <Link href='/' className='flex items-center gap-2' prefetch={false} data-sentry-element="Link" data-sentry-source-file="login.tsx">
          <Image src={'/LogoWT-Air Reserve_transparent.png'} width={150} height={100} alt='Logo' data-sentry-element="Image" data-sentry-source-file="login.tsx" />
        </Link>
      </header>
      <div className='flex grow content-center items-center p-5'>
        <div className='hidden flex-col p-5 sm:block'>
          <Image className='mx-auto' src={'/LogoAirReserve.png'} width={350} height={300} alt='Logo' data-sentry-element="Image" data-sentry-source-file="login.tsx" />
        </div>
        <div className='mx-auto flex-col p-5'>
          <Button onClick={() => {
          window.location.assign(`${window.location.protocol}//${window.location.host}/api/logto/sign-in`);
        }} data-sentry-element="Button" data-sentry-source-file="login.tsx">
            Click Here to Sign-In
          </Button>
        </div>
      </div>
      <Footer data-sentry-element="Footer" data-sentry-source-file="login.tsx" />
    </div>;
}