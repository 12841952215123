import NavBar from '@/components/navigation/navbar';
import Footer from '@/components/navigation/footer';
export default function Layout({
  children
}: {
  children: React.ReactNode;
}) {
  return <>
      <div className='flex h-screen flex-col'>
        <NavBar data-sentry-element="NavBar" data-sentry-source-file="defaultLayout.tsx" />
        <div className='grow p-5'>{children}</div>
        <Footer data-sentry-element="Footer" data-sentry-source-file="defaultLayout.tsx" />
      </div>
    </>;
}